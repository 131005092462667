import React from "react";
import {
	BlueSquares1,
	BlueSquares2,
	PinkCircles1,
	PinkSquares0,
} from "@assets/Background";
import { PageLayout } from "@components";
import BackgroundFadeContainer from "@components/BackgroundFadeContainer/BackgroundFadeContainer";
import { BackgroundShapes } from "@components/BackgroundShapes/BackgroundShapes";
import { StaticImage } from "gatsby-plugin-image";
import { pinkText } from "@components/Typography/Typography";
import ImageAndBulletPoints from "@components/ImageAndBulletPoints/ImageAndBulletPoints";
import { SectionTitle } from "@components/SectionTitle/SectionTitle";
import {
	ArrowLinkButton,
	GetStartedButton,
} from "@components/Button_new/Button";
import { IntegrateYourResearch } from "@components/IntegrateYourResearch/IntegrateYourResearch";
import LearnMoreSection from "@components/LearnMoreSection/LearnMoreSection";
import { useWindowSize } from "@helpers/Hooks";
import * as styles from "../../CommonResourcesOverview.module.scss";

export default function Admin() {
	return (
		<PageLayout
			seoOptions={{
				type: "PREDEFINED",
				pageName: "life-science-project-management",
			}}
		>
			<ModernLabAutomation />
			<OnePlatform />
			<IntegrateYourResearch />
			<EntireDepartment />
			<LearnMoreSection textColor="ui-01" background="blueFade" />
		</PageLayout>
	);
}

const ModernLabAutomation = () => (
	<BackgroundFadeContainer type="blueFade" skewBottom>
		<BackgroundShapes
			leftShape={{ shape: <BlueSquares1 />, xOffset: -20 }}
			rightShape={{
				shape: <BlueSquares2 />,
				yOffset: 200,
			}}
		>
			<div
				style={{
					display: "flex",
					alignItems: "center",
					flexDirection: "column",
				}}
			>
				<SectionTitle
					title="The modern lab automation platform built with admins in mind"
					subtitle="Genemod Enterprise enables large institutions with multiple teams to centralize projects and experiments on one platform."
					textColor="ui-01"
					titleWidth={860}
					subtitleWidth={700}
					marginTop={163}
					marginTopTablet={186}
					marginTopMobile={146}
				/>
				<GetStartedButton />
				<StaticImage
					loading="eager"
					style={{ maxWidth: 1200 }}
					className={styles.fullWidthImage}
					src="../../../assets/products/multiworkspace-admin-console.png"
					alt="example screenshot of project organization webpage"
				/>
			</div>
		</BackgroundShapes>
	</BackgroundFadeContainer>
);

const OnePlatform = () => {
	const { isMobile } = useWindowSize();
	return (
		<BackgroundFadeContainer type="pinkFade" skewTop skewBottom>
			<BackgroundShapes
				rightShape={{ shape: <PinkCircles1 />, yOffset: 250 }}
			>
				<SectionTitle
					title="One platform to manage multiple research teams"
					textColor="text-dark"
					titleWidth={602}
					marginBottom={60}
					marginBottomTablet={60}
				/>
				<ImageAndBulletPoints
					bullets={[
						{
							title: "Leverage advanced enterprise features",
							text: "Access advanced features such as role-based permissions, admin dashboard, and user provisioning, which give you complete control over your organization’s data management and user profiles.",
						},
						{
							title: pinkText`Manage ${"multiple research teams"} on Genemod Enterprise`,
							text: "Org Owners and Org Admins can decide who can manage multi-workspace research teams within an Enterprise Grid organization.",
						},
						{
							title: "Work with external organizations, securely",
							text: "Retain your data security practices while collaborating with trusted organizations.",
						},
					]}
					image={
						<StaticImage
							loading="eager"
							src="../../../assets/products/admin-console-manage-user.png"
							alt="manage users example"
							width={786}
							height={591}
						/>
					}
					bulletStyles={{
						titleVariant: "HEADER4",
						titleColor: "text-dark",
						textVariant: "SUBHEADERDESC",
						textColor: "text-dark",
						containerWidth: 476,
					}}
				/>
			</BackgroundShapes>

			{/* ~~~~~~~~~~~~~~~~ #1 in analytics ~~~~~~~~~~~~~~~~~ */}
			<BackgroundShapes
				rightShape={{ shape: <PinkCircles1 />, yOffset: 200 }}
			>
				<SectionTitle
					title="#1 in analytics for life science research operations"
					textColor="text-dark"
					titleWidth={1180}
					marginTop={195}
					marginTopTablet={116}
					marginTopMobile={60}
					marginBottom={60}
					marginBottomTablet={60}
				/>
				<ImageAndBulletPoints
					bullets={[
						{
							title: pinkText`Accelerate your ${"innovation"}`,
							text: "Enable research teams to easily see the complete picture and take faster action.",
						},
						{
							title: "Where project management meets electronic lab notebook",
							text: "Group similar research experiments in subprojects and see how Genemod helps you optimize your time and resources.",
						},
						{
							title: "Integrate your research workflows and database",
							text: "We integrate with Google Drive and more. Feel the power of file management & Genemod for research.",
						},
					]}
					image={
						<StaticImage
							loading="eager"
							src="../../../assets/products/project_metrics.png"
							alt="ELN project metrics page"
							width={786}
							height={591}
						/>
					}
					bulletStyles={{
						titleVariant: "HEADER4",
						titleColor: "text-dark",
						textVariant: "SUBHEADERDESC",
						textColor: "text-dark",
						containerWidth: 476,
					}}
				/>
			</BackgroundShapes>
		</BackgroundFadeContainer>
	);
};

const EntireDepartment = () => (
	<BackgroundFadeContainer type="pinkFade" skewTop skewBottom>
		<BackgroundShapes
			rightShape={{ shape: <PinkSquares0 />, yOffset: 400 }}
		>
			<SectionTitle
				title={pinkText`The modern R&D platform where your ${"entire department"} is aboard`}
				textColor="text-dark"
				titleWidth={700}
				marginBottom={60}
				marginBottomTablet={60}
			/>
			<ImageAndBulletPoints
				bullets={[
					{
						title: "We offer site-wide discounts for departments and cores",
						text: (
							<>
								{
									"Ask your department if they offer Genemod as a preferred software vendor."
								}{" "}
								<div style={{ marginTop: 20 }}>
									<ArrowLinkButton
										color="marine"
										link="CONTACT_US"
										state={{
											defaultSubject: "Billing",
										}}
									>
										Contact us
									</ArrowLinkButton>
								</div>
							</>
						),
					},
					{
						title: "Finally, an ELN built for scientists",
						text: "It’s never been easier to cycle across all your experiments, protocols, and attachments in one place.",
					},
					{
						title: "A source of truth for your inventory",
						text: "From reagents to custom item types - we customize to match exactly how it is in the lab.",
					},
				]}
				image={
					<StaticImage
						loading="eager"
						src="../../../assets/products/subproject.png"
						alt="screenshot of subproject page"
						width={786}
						height={586}
					/>
				}
				bulletStyles={{
					titleVariant: "HEADER4",
					titleColor: "text-dark",
					textVariant: "SUBHEADERDESC",
					textColor: "text-dark",
					containerWidth: 476,
				}}
			/>
		</BackgroundShapes>
	</BackgroundFadeContainer>
);
